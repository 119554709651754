<template>

  <div class="container">

    
    <div class="card" style="display: none;">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div style="width: 100%;" class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0"> الاســــــــر </h5>
            </div>
            <div class="head-label col-md-6">
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" @keyup="querySearch" type="text" placeholder="">
                  <label for="html5-date-input">البحث اسم العائلة او رقم الهاتف </label>
              </div>
            </div>
          </div>
        </div>

        <table class="datatables-basic table table-bordered">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>الاســـــم</th>
                  <th>الهاتف</th>
                  <th>واتساب</th>
                  <th style="width: 130px;">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> <router-link :to="'/family.members/'+item.id">{{ item.name }}  </router-link></td>
                <td> <router-link :to="':tel/'+item.mobile">{{ item.mobile }}  </router-link></td>
                <td> <router-link :to="'/family/'+item.whatsapp">{{ item.whatsapp }}  </router-link></td>
                
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name, item.mobile, item.whatsapp)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>



  <form class="row" style="width: 100%;" @submit.prevent=""  @submit="filtters()">
    <div class="form-floating form-floating-outline mb-2 col-6">
      <input class="form-control" style="width: 100%;" v-model="filter.search" type="text" placeholder="">
      <label for="html5-date-input">البحث اسم العائلة او رقم الهاتف </label>
    </div>
    <div class="col-6">
      <div class="form-floating form-floating-outline mb-2">
        <select class="form-control form-select" v-model="filter.status">
          <option></option>
          <option value="wating">تم الدعوة</option>
          <option value="delay">موجلة</option>
          <option value="refuse">رافض</option>
          <option value="done">تم</option>
        </select>
        <label for="html5-date-input">الحالة</label>
      </div>
    </div><!--col-6-->
    <div class="col-6">
      <div class="form-floating form-floating-outline mb-2">
        <select class="form-control form-select" v-model="filter.village">
          <option></option>
          <option v-for="item in loopHomeVillage" :key="item" :value="item.id">{{ item.name }}</option>
        </select>
        <label for="html5-date-input">القري والمناطق</label>
      </div>
    </div><!--col-6-->
    <div class="col-6">
      <div class="form-floating form-floating-outline mb-2">
        <select class="form-control form-select" v-model="filter.street">
          <option></option>
          <option v-for="item in loopHomeStreet" :key="item" :value="item.id">{{ item.name }}</option>
        </select>
        <label for="html5-date-input"> الشوارع او العائلات</label>
      </div>
    </div><!--col-6-->
    <div class="form-floating form-floating-outline mb-2 col-12">
      <button type="submit" style="width: 100%;" class="btn btn-primary waves-effect waves-light">بحــــــث</button>
    </div>

  </form>


<!--
          <div v-for="prayer in loopHomePrayer">
          <div v-if="prayer.family == item.id && prayer.status == 'wating'" class="status-color" style="background: #ffb400;">.</div>
          <div v-if="prayer.family == item.id && prayer.status == 'delay'" class="status-color" style="background: #3b5998;">.</div>
          <div v-if="prayer.family == item.id && prayer.status == 'refuse'" class="status-color" style="background: #ff4c51;">.</div>
          <div v-if="prayer.family == item.id && prayer.status == 'done'" class="status-color" style="background: #009688;">.</div>
          <div v-if="!prayer.family" class="status-color" style="background: rgb(198 198 198);">.</div>
        </div>
-->

<table class="datatables-basic table ">

  <tbody>
    <tr v-for="(item) in loopData" :key="item">
      <td >
          <div v-if="item.home_prayer_status == 'wating'"  class="status-color" style="background: #ffb400;">.</div>
          <div v-if="item.home_prayer_status == 'delay'"   class="status-color" style="background: #3b5998;">.</div>
          <div v-if="item.home_prayer_status == 'refuse'"  class="status-color" style="background: #ff4c51;">.</div>
          <div v-if="item.home_prayer_status == 'done'"   class="status-color" style="background: #009688;">.</div>
          <div v-if="item.home_prayer_status == 'none'"  class="status-color" style="background: rgb(198 198 198);">.</div>
      </td>
      <td style="width: 190px;">
        <strong>{{ item.name }}</strong>
        <span>شارع حسن بلال</span>
      </td>
      <td>
        <div class="route-link">
          <router-link :to="'/family/'+item.whatsapp"> <i class="fab fa-whatsapp"></i> </router-link>
          <router-link :to="':tel/'+item.mobile"> <i class="far fa-phone-plus"></i>  </router-link>
        </div>
      </td>
      <td>
        <div class="dropdown">
          <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="mdi mdi-dots-vertical"></i>
          </button>
          <div class="dropdown-menu" style="">
              <a class="dropdown-item waves-effect" href="javascript:void(0);" @click="updateStatus(item.id, 'wating')"><i class="far fa-comments-alt  me-1"></i>ارسال دعوة</a>
              <a class="dropdown-item waves-effect" href="javascript:void(0);" @click="updateStatus(item.id, 'done')"><i class="far fa-check-circle  me-1"></i> تم الصلاة</a>
              <a class="dropdown-item waves-effect" href="javascript:void(0);" @click="updateStatus(item.id, 'delay')"><i class="far fa-history me-1"></i>تاجيل</a>
              <a class="dropdown-item waves-effect" href="javascript:void(0);" @click="updateStatus(item.id, 'refuse')"><i class="far fa-ban me-1"></i>غير راغب</a>
              <!--
              <div class="form-floating form-floating-outline mb-4">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="ملاحظات">
                  <label for="exampleFormControlInput1">ملاحظات</label>
              </div>
              -->
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>



  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding-right: 0rem;
    padding-left: 0rem;
}

strong {
  display: block;
  color: black;
}

span {
    font-size: 13px;
    line-height: 0px;
    top: -4px;
    position: relative;
}

.route-link a {
    font-size: 20px;
    padding: 10px;
}

.table .status-color {
    width: 20px;
    height: 20px;
    background: aqua;
    border-radius: 16px;
}

.table > :not(caption) > * > * {
   padding: 5px;
}
.row > * {
  padding-left: 0px;
  padding-right: 5px;
}
</style>



<script>
import axios from 'axios'
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopData    : [],
      loopHomePrayer: [],
      loopHomeVillage: [],
      loopHomeStreet: [],

      name : '',
      mobile : '',
      whatsapp : '',

      edit_id: '',
      edit_name : '',
      edit_mobile : '',
      edit_whatsapp : '',

      filter: {
        search : '',
        status : '',
        village : '',
        street : '',
      }

      
    }
  },

  mounted () {
      window.document.title = "العائلات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    updateStatus(family, status) {
      axios.post(`/store.home.prayer`, {
        family       : family,
        status       : status,
      });
      this.getData();
    },



    getData(){
      axios.get(`home.prayer`)
        .then(res => {
          this.loopData         = res.data.data
          this.loopHomePrayer   = res.data.home_prayer
          this.loopHomeVillage  = res.data.village
          this.loopHomeStreet   = res.data.street
        })
    },



    // Get Data For Edit
    openEditModel (id, name, mobile, whatsapp) {
      this.edit_id            = id
      this.edit_name          = name
      this.edit_mobile        = mobile
      this.edit_whatsapp      = whatsapp
    }, // openEditModel

    update () {
      axios.post(`/update.family`, {
        id           : this.edit_id,
        name         : this.edit_name,
        mobile       : this.edit_mobile,
        whatsapp     : this.edit_whatsapp
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },


    filtters() {
      alert("hkl")
        axios.get("home.prayer"
        + "?search="          + this.filter.search
        + "&status="          + this.filter.status
        + "&village="         + this.filter.village
        + "&street="          + this.filter.street
        ) 
        .then(res => {
            this.loopData = res.data;
          })
      },





 }
}
</script>
   