<script lang="ts">
  
import axios from 'axios'
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
export default {

  name: "MenuRight",
  data() {
      return {
        user_id     : localStorage.getItem("user_id"),
        status_auth : localStorage.getItem('status_auth'),
        acount_type : localStorage.getItem('acount_type'),
        notifications: [],
        startInterval: null,
        count_notification: ""
      }
    },  
  mounted () {
    if (localStorage.getItem("status_auth")) {
      //this.getCount();
      setInterval(() => {
        //return this.getCount();
      }, 10000);
    } // status_auth
  
    
  }, // mounted

  methods : {

    getCount(){
      axios.get(`notifications`)
        .then(res => {
            //this.count_notification = 1
       })
    },

    logout () {
      localStorage.removeItem("user_id");
      localStorage.removeItem("status_auth");
      localStorage.removeItem("token");
      localStorage.removeItem("acount_type");
      localStorage.removeItem("reload");
      window.location.href = '/';
    },


  }

  // filter Payment
  // Dashbord

  //whatsapp
  //certificate_type
  //certificate
  //reason_travel
  //live_status
  // travel_status

}
</script>


<template>

        <aside id="layout-menu" v-if="$route.name != 'home' && $route.name != 'login' && $route.name != 'signup'" style="display: block;" class="layout-menu menu-right menu-vertical menu bg-menu-theme">
          <div class="app-brand demo">
            <a href="index.html" class="app-brand-link">
              <span class="app-brand-logo demo me-1">
                <span style="color: var(--bs-primary)">
                
                </span>
              </span>
              <span class="app-brand-text demo menu-text fw-semibold ms-2">Materio</span>
            </a>

            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
              <i class="mdi menu-toggle-icon d-xl-block align-middle mdi-20px"></i>
            </a>
          </div>

          <div class="menu-inner-shadow"></div>

          <ul class="menu-inner py-1">

          <!-- Dashboard -->
            <li class="menu-item">
              <router-link to="/dashbord" class="menu-link">
                <i class="far fa-chart-line"></i>
                <div> لوحة الاحصائيات  </div>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link to="/profile" class="menu-link">
                <i class="far fa-user-cog"></i>
                <div> الملف الشخصي </div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/notifications" class="menu-link">
                <i class="far fa-comments-alt"></i>
                <div> الاشعارات</div>
                <div class="badge bg-danger rounded-pill ms-auto">{{count_notification}}</div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/members/0" class="menu-link">
                <i class="far fa-users"></i>
                <div> ملف الموظفين </div>
              </router-link>
            </li>  
            <li class="menu-item">
              <router-link to="/village" class="menu-link">
                <i class="far fa-users"></i>
                <div> القري او المناطق </div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="family.search" class="menu-link">
                <i class="far fa-users"></i>
                <div>الاســـــر</div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="/members.search" class="menu-link">
                <i class="far fa-users"></i>
                <div>المخدومين</div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="/mention.dead" class="menu-link">
                <i class="far fa-users"></i>
                <div>الترحيم</div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="/home.prayer" class="menu-link">
                <i class="far fa-users"></i>
                <div>صلاة تبريك المنازل</div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="family" class="menu-link">
                <i class="far fa-users"></i>
                <div>الاشتراك الشهري</div>
              </router-link>
            </li> 

            <li class="menu-item">
              <router-link to="/kg" class="menu-link">
                <i class="far fa-users"></i>
                <div>تمهيدي</div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="/kg" class="menu-link">
                <i class="far fa-users"></i>
                <div>KG 1</div>
              </router-link>
            </li> 
            <li class="menu-item">
              <router-link to="/kg" class="menu-link">
                <i class="far fa-users"></i>
                <div>KG 2</div>
              </router-link>
            </li> 


            <li class="menu-item" @click="logout()">
              <a href="#" class="menu-link">
                <i class="fal fa-sign-out-alt"></i>
                <div  >تسجيل الخروج</div>
              </a>
            </li>

          </ul>
        </aside>
        <!-- / Menu -->

</template>

<style>
.menu-right i {
    font-weight: 400;
    margin-left: 15px;
    font-size: 21px;
    width: 30px;
    text-align: center;
}

#layout-menu {
  overflow: scroll;
}
</style>
