
<template>
  <div class="">
    <br><br>
    <div class="card">
      <div class="box row">
      <div class="col-md-8">
        <div class="chat">
          <div v-for="item in loopData" :key="item" class="user">
            <div class="message">{{ item.message }}</div>
            <span>10:05 AM</span>
          </div>
          <div class="admin"></div>

          <div>
            <br>
            <div class="form-floating form-floating-outline mb-3 fv-plugins-icon-container">
              <input v-model="message" type="text" class="form-control" id="email" name="email-username" placeholder="Type Your Message" autofocus="">
              <label for="email">Text Message</label>
              <button @click="sendMessage()">Send Message</button>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="leftBox">

          <div class="list">

            <div v-for="item in loopEmployee" :key="item" @click="getChat(item.id)" class="FlexBox">
              <img src="file:///C:/Users/Mena/Downloads/materio-bootstrap-html-admin-template-v1.1.1/materio-bootstrap-html-admin-template/materio-bootstrap-html-admin-template/assets/img/avatars/4.png" alt="">
              <div> {{ item.name }} </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    </div>
  </div>
</template>


<style>
.chat {
  padding: 30px;
  background: #f7f7f7;
}
.chat .message {
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(58, 53, 65, 0.2), 0px 1px 1px rgba(58, 53, 65, 0.14), 0px 1px 3px rgba(58, 53, 65, 0.12);
}

.chat .message {
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(58, 53, 65, 0.2), 0px 1px 1px rgba(58, 53, 65, 0.14), 0px 1px 3px rgba(58, 53, 65, 0.12);
    padding: 10px;
    max-width: 331px;
    border-radius: 5px;
    margin-top: 20px;
}

.chat span {
    font-size: 11px;
}

ul li {
  padding: 5px;
}
</style>


<script>
if(process.env.NODE_ENV == "development") {
  var urlSite = "http://127.0.0.1:8000/api";
} else {
  var urlSite = "https://furncn.com/back-end/api";
}
import axios from 'axios'
import { useRoute } from 'vue-router'
const route = useRoute();
 export default {
  name: "Member",

  data() {
    return {

      loopData: [],
      loopEmployee: [],

      receiver: null,
      message: null,
    }
  },
 
  mounted () {
      window.document.title = "Device";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }

      this.getData();
      setInterval(() => {
        return this.getData();
      }, 5000);

  }, // mounted




  methods : {

    getChat(id) {
      this.receiver = id
    },

    sendMessage() {
      const dataContent = {
        message : this.message,
        receiver: this.receiver
      }
      axios.post(`${urlSite}/send.message.chat`, dataContent)
        .then(res => {
        })   
    },
    
    getData(){
      axios.get(`${urlSite}/chat/${this.receiver}`)
        .then(res => {
          this.loopEmployee = res.data.employee
          this.loopData = res.data.data
        })    
    },


    store  () {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("price", this.price);
      axios.post(`store.device`, formData)
      .then(res => {
          this.getData();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully Registered',
            text: "The Request Was Added Successfully :)",
            showConfirmButton: false,
            timer: 1500
          })
      }).catch(error => {
        console.log(error);
      });
      
    },








 }
}
</script>
   