<template>

  <div class="container">
    <form class="card" @submit.prevent="" @submit="filters()">
      <div class="row justify-content-center">
        <div class="form-floating form-floating-outline mb-2 col-md-1">
            <input class="form-control" v-model="filter.start" type="number" id="html5-date-input">
            <label for="html5-date-input">من اليوم</label>
        </div>
        <div class="form-floating form-floating-outline mb-2 col-md-1">
            <input class="form-control" v-model="filter.end" type="number" id="html5-date-input">
            <label for="html5-date-input">الي اليوم</label>
        </div>
        <div class="form-floating form-floating-outline col-md-2">
          <select id="collapsible-state" class="select2 form-select" v-model="filter.month" data-allow-clear="true">
            <option value="01">1 - يناير</option>
            <option value="02">2 - فبراير </option>
            <option value="03">3 - مارس</option>
            <option value="04">4 - أبريل</option>
            <option value="05">5 - مايو</option>
            <option value="06">6 - يونيو</option>
            <option value="07">7 - يوليو</option>
            <option value="08">8 - أغسطس</option>
            <option value="09">9 - سبتمبر</option>
            <option value="10">10 - أكتوبر</option>
            <option value="11">11 - نوفمبر</option>
            <option value="12">12 - ديسمبر</option>
          </select>
          <label for="collapcol-md-2">الشهــــر</label>
        </div>
        <div class="form-floating form-floating-outline mb-2 col-md-3">
            <input class="form-control" v-model="name" type="text" id="html5-date-input">
            <label for="html5-date-input">بحــــــث</label>
        </div>
        <button class="dt-button create-new btn btn-primary"   type="submit"><span><span class="d-none d-sm-inline-block">بحــــــث</span></span></button>
      </div> <!-- row -->
    </form>
    <br>
    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <h5 style="text-align: right;" class="card-title">الترحيــــــم</h5>
          <br>
        <table class="datatables-basic table table-bordered">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>الاســـــم</th>
                  <th>العلاقة</th>
                  <th>تاريخ الوفاه</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }} </td>
                <td> {{ item.relationship }} </td>
                <td> {{ item.fulfillment_date }} </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>


  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

select, input {
  height: 48px;
  margin-bottom: 15px;
}
</style>



<script>
import axios from 'axios'
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopData    : [],
      loopCertificate      : [],
      loopcertificateType  : [],
      loopEducationLevel   : [],
      loopJobs             : [],


      filter: {
        start    : "",
        end      : "",
        month    : "",
      }

      
    }
  },

  mounted () {
      window.document.title = "الترحيم";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`mention.dead`)
        .then(res => {
          this.loopData             = res.data
        })
    },


    filters() {
        axios.get("mention.dead"
        + "?start="     + this.filter.start
        + "&end="       + this.filter.end
        + "&month="     + this.filter.month
        ) 
        .then(res => {
            this.loopData = res.data;
          })
      },

      CertificateType() {
        axios.get(`certificate/${event.target.value}`).then(res => {
            this.loopCertificate = res.data;
      })
    },





 }
}
</script>
   