<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'

import MenuNavTop from "../src/components/MenuNavTop.vue"
import MenuRight from "../src/components/MenuRight.vue"
if(localStorage.getItem("status_auth")) {
    const user_id = localStorage.getItem("user_id");
    const status_auth = localStorage.getItem("status_auth");    
    console.log(status_auth)
}
</script>
 

<template>
    <MenuRight />
    <RouterView />
</template>


<style>
.form-control .form-select {
  width: 100%;
}
.searchBox .form-check {
    display: block;
    min-height: 30px;
    /* padding-left: 1.7em; */
    margin-bottom: 0.125rem;
    /* text-align: left; */
}

.btn-action {
    color: #696cff;
    padding: 0px 8px;
    font-size: 20px;
}

.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
    padding-top: 10px !important; 
}
</style>


   