import { createRouter, createWebHistory, createWebHashHistory,  RouteRecordRaw } from "vue-router";

import HomeView from "../views/HomeView.vue";
import Spinners from "../views/Spinners.vue";



import FamilySearch from "../views/Search/Family.vue";
import MembersSearch from "../views/Search/Members.vue";


import MentionDead from "../views/MentionDead.vue";


import Village from "../views/Village.vue";
import Street from "../views/Street.vue";
import Family from "../views/Family.vue";
import FamilyMembers from "../views/FamilyMembers.vue";
import HomePrayer from "../views/HomePrayer.vue";



import details from "../views/details.vue";

//Contact
import Chat from "../views/Contact/Chat.vue";
import Message from "../views/Contact/Message.vue";



// Member
import SignUp from "../views/Member/SignUp.vue";
import Login from "../views/Member/Login.vue";
import ForgotPassword from "../views/Member/ForgotPassword.vue";
import Profile from "../views/Member/Profile.vue";
import Notifications from "../views/Member/Notifications.vue";
import Member from "../views/Member/Member.vue";


//import Dashbord from "../views/Dashbord/Dashbord.vue";
//import DashbordLeads from "../views/Dashbord/Leads.vue";


const routes: Array<RouteRecordRaw> = [

  

  {path: "/", name: "home", component: HomeView },

  {path: "/family.search", name: "FamilySearch", component: FamilySearch },
  {path: "/members.search", name: "MembersSearch", component: MembersSearch },

  
  {path: "/village", name: "Village", component: Village },
  {path: "/street/:id", name: "Street", component: Street },
  {path: "/family/:id", name: "family", component: Family },
  {path: "/family.members/:id", name: "FamilyMembers", component: FamilyMembers },

  
  { path: "/mention.dead", name: "MentionDead", component: MentionDead}, 

  { path: "/chat", name: "chat", component: Chat}, 
  { path: "/message", name: "Message", component: Message}, 

  // Member
  { path: "/signUp", name: "SignUp", component: SignUp}, 
  { path: "/login", name: "login", component: Login}, 
  { path: "/forgotPassword", name: "ForgotPassword", component: ForgotPassword}, 
  { path: "/profile", name: "Profile", component: Profile}, 
  { path: "/notifications", name: "Notifications", component: Notifications}, 
  { path: "/members/:id", name: "Member", component: Member}, 
  { path: "/home.prayer", name: "HomePrayer", component: HomePrayer}, 

  


];


const router = createRouter({
  history: createWebHashHistory(),
  //mode: 'history',
  routes,

});


export default router;



