
<template>
  <!--
  https://www.pornhub.com/pornstar/violet-starr/videos
  https://www.pornhub.com/model/bananacoko
  https://www.pornhub.com/pornstars?page=4
  https://xn--mgbkt9eckr.net/threads/%D8%A3%D8%AE%D8%AA%D9%8A-%D8%A7%D9%84%D9%85%D8%B7%D9%84%D9%82%D8%A9-%D8%AA%D8%B3%D8%AA%D8%BA%D9%84-%D9%87%D9%8A%D8%AC%D8%A7%D9%86%D9%8A-%D8%A7%D9%84%D8%B3%D9%84%D8%B3%D9%84%D8%A9-%D8%A7%D9%84%D8%A3%D9%88%D9%84%D9%8A-%D9%80-%D8%B9%D8%B4%D8%B1%D8%A9-%D8%A3%D8%AC%D8%B2%D8%A7%D8%A1-10-6-2022.79139/
  https://www.tubev.pro/categories/1673/girls-kissing?hl=ar

<div class="form-floating form-floating-outline">
  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true">
    <option value="">Select</option>
    <option value="AL">Alabama</option>
    <option value="AK">Alaska</option>
    <option value="AZ">Arizona</option>
    <option value="AR">Arkansas</option>
  </select>
  <label for="collapsible-state">State</label>
</div>
-->
  <div class="container">

    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div style="width: 100%;" class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">القري والمناطق</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>

        <table class="datatables-basic table table-bordered">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>المنطقة</th>
                  <th style="width: 130px;">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> <router-link :to="'/street/'+item.id">{{ item.name }}  </router-link></td>
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id,	item.name)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" v-model="name" type="text" id="html5-date-input">
                  <label for="html5-date-input">القرية او المنطقة</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- تحديث البيانات -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <input type="hidden" v-model="edit_id">
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" v-model="edit_name" type="text" id="html5-date-input">
                  <label for="html5-date-input">القرية او المنطقة</label>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- تحديث البيانات -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}
</style>



<script>
import axios from 'axios'
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopData    : [],

      name: '',
      edit_name: '',
      id: '',


      filter: {
        start          : "",
        end            : "",
        document_id    : "",
        customer_name  : "",
        customer_phone : "",
        amount_from    : "",
        amount_to      : "",
        customer_id    : "",
        reason         : "",
      }

      
    }
  },

  mounted () {
      window.document.title = "القري والمناطق";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`village`)
        .then(res => {
          this.loopData     = res.data
        })
    },

    store  () {
      axios.post(`store.village`, {
        name        : this.name,

      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام البيانات بنجاح',
            showConfirmButton: false,
            timer: 1000
          })
      });

    },

    // Get Data For Edit
    openEditModel (id, name) {
      this.edit_id            = id
      this.edit_name          = name
    }, // openEditModel

    update () {
      axios.post(`/update.village`, {
        id           : this.edit_id,
        name         : this.edit_name,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`destroy`, {
          id   : id, 
          table: "village", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete


    filtter() {
        axios.get("financial.bonds"
        + "?start="           + this.filter.start
        + "&end="             + this.filter.end
        + "&document_id="     + this.filter.document_id
        + "&customer_name="   + this.filter.customer_name
        + "&customer_phone="  + this.filter.customer_phone
        + "&amount_from="     + this.filter.amount_from
        + "&amount_to="       + this.filter.amount_to
        + "&customer_id="     + this.filter.customer_id
        + "&reason="          + this.filter.reason

        ) 
        .then(res => {
            this.loopData = res.data;
          })
      },





 }
}
</script>
   