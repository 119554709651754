<template>

  <div class="container">

    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div style="width: 100%;" class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0">الشوارع او العائلات</h5>
            </div>
            <div class="dt-buttons  col-md-6"> 
              <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="button">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
            </div>
          </div>
        </div>

        <table class="datatables-basic table table-bordered">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>الاســـــم</th>
                  <th>العلاقة</th>
                  <th>الهاتف</th>
                  <th>واتساب</th>
                  <th>الرقم القومي</th>
                  <th>تاريخ الميلاد</th>
                  <th>الوظفيه</th>
                  <th>نوع الشهادة</th>
                  <th>الشهادة</th>
                  <th>المرحلة الدراسية</th>
                  <th>الحالة الاجتماعية</th>
                  <th>الحالة</th>
                  <th>تاريخ الوفاه</th>
                  <th>السفر</th>
                  <th>البلد</th>
                  <th>سبب السفر</th>
                  <th>ملاحظات</th>
                  <th style="width: 130px;">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopData" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> {{ item.name }} </td>
                <td> {{ item.relationship }} </td>
                <td> {{ item.mobile }} </td>
                <td> {{ item.whatsapp }} </td>
                <td> {{ item.id_number }} </td>
                <td>
                  <div style="width: 70px;">{{ item.birthday }}</div>
                </td>
                <td> {{ item.job }} </td>
                <td> {{ item.certificate_type }} </td>
                <td> {{ item.certificate }} </td>
                <td> {{ item.education_level }} </td>
                <td> {{ item.marital_status }} </td>
                <td> {{ item.live_status }} </td>
                <td>
                  <div style="width: 70px;">{{ item.fulfillment_date }}</div>
                </td>
                <td> {{ item.travel_status }} </td>
                <td> {{ item.country }} </td>
                <td> {{ item.reason_travel }} </td>
                <td> {{ item.note }} </td>

                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name, item.mobile, item.whatsapp, item.id_number, item.birthday, item.job, item.education, item.education_level, item.marital_status, item.fulfillment_date, item.fulfillment_status, item.work_abroad, item.country, item.family, item.relationship, item.certificate_type, item.certificate, item.reason_travel, item.live_status, item.travel_status)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>



    <!-- Create Row -->
    <div class="modal fade" id="newModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="store()" method="POST" class="modal-content"  enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> اضافة </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">


              <div class="row">
                <div class="form-floating form-floating-outline mb-2 col-md-12">
                  <input class="form-control" v-model="name" type="text" placeholder="">
                  <label for="html5-date-input"> الاســـــم </label>
              </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="mobile" type="number" placeholder="">
                    <label for="html5-date-input"> رقم الهاتف </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="whatsapp" type="number" placeholder="">
                    <label for="html5-date-input"> واتساب </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="id_number" type="number" placeholder="">
                    <label for="html5-date-input"> الرقم القومي </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="birthday" type="date" placeholder="">
                    <label for="html5-date-input"> تاريخ الميلاد </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="relationship">
                    <option value=""></option>
                    <option  value="زوج">زوج</option>
                    <option  value="زوجة">زوجة</option>
                    <option  value="ابن">ابن</option>
                    <option  value="ابنة">ابنة</option>
                  </select>
                  <label for="collapsible-state">العلاقة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="marital_status">
                    <option value=""></option>
                    <option  value="متزوج">متزوج</option>
                    <option  value="ارمل">ارمل</option>
                    <option  value="مطلق">مطلق</option>
                  </select>
                  <label for="collapsible-state">الحالة الاجتماعية</label>
                </div>

                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <input class="form-control" v-model="certificate_type" type="text" placeholder="">
                  <label for="collapsible-state">نوع الشهادة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                    <input class="form-control" v-model="certificate" type="text" placeholder="">
                  <label for="collapsible-state">الشهادة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                    <input class="form-control" v-model="education_level" type="text" placeholder="">
                    <label for="collapsible-state">المرحلة الدراسية</label>
                </div>

                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="travel_status">
                    <option value=""></option>
                    <option  value="مقيم">مقيم</option>
                    <option  value="بالخارج">بالخارج</option>
                  </select>
                  <label for="collapsible-state">الاقامة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                    <input class="form-control" v-model="country" type="text" placeholder="">
                    <label for="html5-date-input"> الدولة </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="reason_travel">
                    <option value=""></option>
                    <option  value="عمل">عمل</option>
                    <option  value="تعليم">تعليم</option>
                  </select>
                  <label for="collapsible-state">سبب السفر</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <input class="form-control" v-model="job" type="text" placeholder="" >
                  <label for="collapsible-state">المهنة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="live_status">
                    <option value=""></option>
                    <option  value="قيد الحياة">قيد الحياة</option>
                    <option  value="متوفي">متوفي</option>
                  </select>
                  <label for="collapsible-state">الحالة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                    <input class="form-control" v-model="fulfillment_date" type="date" placeholder="" >
                    <label for="html5-date-input"> تاريخ الوفاه </label>
                </div>
                <div class="form-floating form-floating-outline mb-12">
                    <textarea name="" id="" cols="3" rows="3"  class="form-control" v-model="note"></textarea>
                    <label for="html5-date-input"> ملاحظات </label>
                </div>
              </div>


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Create Row -->



    <!-- تحديث البيانات -->
    <div class="modal fade" id="EditModal" tabindex="-1" role="dialog" aria-labelledby="newModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="" @submit="update()" method="POST" class="modal-content" enctype="multipart/form-data">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel"> تحديث البيانات </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <input type="hidden" v-model="edit_id">
              <div class="row">
                <div class="form-floating form-floating-outline mb-2 col-md-12">
                  <input class="form-control" v-model="edit_name" type="text" placeholder="">
                  <label for="html5-date-input"> الاســـــم </label>
              </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="edit_mobile" type="number" placeholder="">
                    <label for="html5-date-input"> رقم الهاتف </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="edit_whatsapp" type="number" placeholder="">
                    <label for="html5-date-input"> واتساب </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="edit_id_number" type="number" placeholder="">
                    <label for="html5-date-input"> الرقم القومي </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                    <input class="form-control" v-model="edit_birthday" type="date" placeholder="">
                    <label for="html5-date-input"> تاريخ الميلاد </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="edit_relationship">
                    <option value=""></option>
                    <option  value="زوج">زوج</option>
                    <option  value="زوجة">زوجة</option>
                    <option  value="ابن">ابن</option>
                    <option  value="ابنة">ابنة</option>
                  </select>
                  <label for="collapsible-state">العلاقة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-6">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="edit_marital_status">
                    <option value=""></option>
                    <option  value="متزوج">متزوج</option>
                    <option  value="ارمل">ارمل</option>
                    <option  value="مطلق">مطلق</option>
                  </select>
                  <label for="collapsible-state">الحالة الاجتماعية</label>
                </div>

                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <input class="form-control" v-model="edit_certificate_type" type="text" placeholder="">
                  <label for="collapsible-state">نوع الشهادة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <input class="form-control" v-model="edit_certificate" type="text" placeholder="">

                  <label for="collapsible-state">الشهادة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                    <input class="form-control" v-model="edit_education_level" type="text" placeholder="">
                    <label for="collapsible-state">المرحلة الدراسية</label>
                </div>

                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="edit_travel_status">
                    <option value=""></option>
                    <option  value="مقيم">مقيم</option>
                    <option  value="بالخارج">بالخارج</option>
                  </select>
                  <label for="collapsible-state">الاقامة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                    <input class="form-control" v-model="edit_country" type="text" placeholder="">
                    <label for="html5-date-input"> الدولة </label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="edit_reason_travel">
                    <option value=""></option>
                    <option  value="عمل">عمل</option>
                    <option  value="تعليم">تعليم</option>
                  </select>
                  <label for="collapsible-state">سبب السفر</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <input class="form-control" v-model="edit_job" type="text" placeholder="" >
                  <label for="collapsible-state">المهنة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                  <select id="collapsible-state" class="select2 form-select" data-allow-clear="true" v-model="edit_live_status">
                    <option value=""></option>
                    <option  value="قيد الحياة">قيد الحياة</option>
                    <option  value="متوفي">متوفي</option>
                  </select>
                  <label for="collapsible-state">الحالة</label>
                </div>
                <div class="form-floating form-floating-outline mb-2 col-md-4">
                    <input class="form-control" v-model="edit_fulfillment_date" type="date" placeholder="" >
                    <label for="html5-date-input"> تاريخ الوفاه </label>
                </div>
                <div class="form-floating form-floating-outline mb-12">
                    <textarea name="" id="" cols="3" rows="3"  class="form-control" v-model="edit_note"></textarea>
                    <label for="html5-date-input"> ملاحظات </label>
                </div>
              </div>


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn rounded-pill btn-secondary" data-dismiss="modal">اغلاق</button>
            <button type="submit" class="btn rounded-pill btn-primary" id="store-bank">  حفــــظ </button>
          </div>
        </form>
      </div>
    </div>
    <!-- تحديث البيانات -->

  </div> <!-- container -->   
</template>


<style  scoped>
.card {
  padding: 25px;
}

.card thead {
  border-width: var(--bs-border-width) 0;
}

select, input {
  height: 48px;
  margin-bottom: 15px;
}
</style>



<script>
import axios from 'axios'
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopData    : [],
      loopCertificate      : [],
      loopcertificateType  : [],
      loopEducationLevel   : [],
      loopJobs             : [],


      name	             : "",
      mobile	           : "",
      id_number	         : "",
      birthday	         : "",
      job	               : "",
      education	         : "",
      education_level	   : "",
      marital_status	   : "",
      fulfillment_date	 : "",
      fulfillment_status : "",
      Work_abroad	       : "",
      country	           : "",
      family	           : "",
      relationship       : "",
      certificate_type   : "",
      certificate        : "",
      reason_travel      : "",
      live_status        : "",
      travel_status      : "",
      note               : "",



      edit_id	                : "",
      edit_name	              : "",
      edit_mobile	            : "",
      edit_id_number	        : "",
      edit_birthday	          : "",
      edit_job	              : "",
      edit_education	        : "",
      edit_education_level	  : "",
      edit_marital_status	    : "",
      edit_fulfillment_date	  : "",
      edit_fulfillment_status : "",
      edit_Work_abroad	      : "",
      edit_country	          : "",
      edit_relationship       : "",
      edit_certificate_type   : "",
      edit_certificate        : "",
      edit_reason_travel      : "",
      edit_live_status        : "",
      edit_travel_status      : "",
      edit_note               : "",


      filter: {
        start          : "",
        end            : "",
        document_id    : "",
        customer_name  : "",
        customer_phone : "",
        amount_from    : "",
        amount_to      : "",
        customer_id    : "",
        reason         : "",
      }

      
    }
  },

  mounted () {
      window.document.title = "الشوارع او العائلات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`family.members/${this.$route.params.id}`)
        .then(res => {
          this.loopData             = res.data.data
          this.loopCertificate      = res.data.certificate
          this.loopcertificateType  = res.data.certificate_type
          this.loopEducationLevel   = res.data.education_level
          this.loopJobs             = res.data.jobs
        })
    },

    store  () {
      axios.post(`store.family.member`, {
      name	             : this.name,
      mobile	           : this.mobile,
      id_number	         : this.id_number,
      whatsapp           : this.whatsapp,
      birthday	         : this.birthday,
      job	               : this.job,
      education	         : this.education,
      education_level	   : this.education_level,
      marital_status	   : this.marital_status,
      fulfillment_date	 : this.fulfillment_date,
      fulfillment_status : this.fulfillment_status,
      Work_abroad	       : this.Work_abroad,
      country	           : this.country,
      family	           : this.$route.params.id,
      relationship       : this.relationship,
      certificate_type   : this.certificate_type,
      certificate        : this.certificate,
      reason_travel      : this.reason_travel,
      live_status        : this.live_status,
      travel_status      : this.travel_status,
      note               : this.note,
      }).then(res => {
        this.getData();
          Swal.fire({
            icon: 'success',
            title: 'تم اتمام البيانات بنجاح',
            showConfirmButton: false,
            timer: 1000
          })
      });

    },




    // Get Data For Edit
    openEditModel (id, name, mobile, whatsapp, id_number, birthday, job, education, education_level, marital_status, fulfillment_date, fulfillment_status, Work_abroad, country, family, relationship, certificate_type, certificate, reason_travel, live_status, travel_status, note) {
      this.edit_id            = id
      this.edit_name      = name
      this.edit_mobile    = mobile
      this.edit_whatsapp  = whatsapp
      this.edit_id_number = id_number
      this.edit_birthday  = birthday
      this.edit_job       = job
      this.edit_education = education
      this.edit_education_level    = education_level
      this.edit_marital_status     = marital_status
      this.edit_fulfillment_date   = fulfillment_date
      this.edit_fulfillment_status = fulfillment_status
      this.edit_work_abroad        = Work_abroad
      this.edit_country            = country
      this.edit_family             = family
      this.edit_relationship       = relationship
      this.edit_certificate_type   = certificate_type
      this.edit_certificate        = certificate
      this.edit_reason_travel      = reason_travel
      this.edit_live_status        = live_status
      this.edit_travel_status      = travel_status
      this.edit_note               = note
    }, // openEditModel

    update () {
      axios.post(`/update.family.member`, {
        id                 : this.edit_id,
        name	             : this.edit_name,
        mobile	           : this.edit_mobile,
        whatsapp           : this.edit_whatsapp,
        id_number	         : this.edit_id_number,
        birthday	         : this.edit_birthday,
        job	               : this.edit_job,
        education	         : this.edit_education,
        education_level	   : this.edit_education_level,
        marital_status	   : this.edit_marital_status,
        fulfillment_date	 : this.edit_fulfillment_date,
        fulfillment_status : this.edit_fulfillment_status,
        Work_abroad	       : this.edit_work_abroad,
        country	           : this.edit_country,
        family	           : this.edit_family,
        relationship       : this.edit_relationship,
        certificate_type   : this.edit_certificate_type,
        certificate        : this.edit_certificate,
        reason_travel      : this.edit_reason_travel,
        live_status        : this.edit_live_status,
        travel_status      : this.edit_travel_status,
        note               : this.edit_note,
      }).then(res => {
        this.getData();
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم تحديث البيانات بنجاح',
            showConfirmButton: false,
            timer: 2000
          })
      });
    },

    onDelete(id) {
      Swal.fire({
      title: 'رسالة تحذيرية',
      text: "هل انت متاكد من حذف البيانات عند حذف لبيانات لا يمكن استرجاعها مرة اخري",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'قم بالحذف'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`destroy`, {
          id   : id, 
          table: "family_members", 
        }).then(res => {
          this.getData();
        });
        Swal.fire(
          'حــــذف !',
          'تم حذف البيانات بنجاح',
          'success'
        )
      }
    })

    }, // onDelete


    filtter() {
        axios.get("financial.bonds"
        + "?start="           + this.filter.start
        + "&end="             + this.filter.end
        + "&document_id="     + this.filter.document_id
        + "&customer_name="   + this.filter.customer_name
        + "&customer_phone="  + this.filter.customer_phone
        + "&amount_from="     + this.filter.amount_from
        + "&amount_to="       + this.filter.amount_to
        + "&customer_id="     + this.filter.customer_id
        + "&reason="          + this.filter.reason

        ) 
        .then(res => {
            this.loopData = res.data;
          })
      },

      CertificateType() {
        axios.get(`certificate/${event.target.value}`).then(res => {
            this.loopCertificate = res.data;
      })
    },





 }
}
</script>
   