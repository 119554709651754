<template>

  <div class="container">

    <div class="card">
      <div class="card-datatable table-responsive pt-0">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <div class="card-header flex-column flex-md-row">

          <div style="width: 100%;" class="dt-action-buttons row text-end pt-3 pt-md-0">
            <div class="head-label col-md-6">
              <h5 style="text-align: right;" class="card-title mb-0"> الاســــــــر </h5>
            </div>
          </div>
        </div>

        <form @submit.prevent="" @submit="filtter()" class="row">
          <div class="head-label col-md-6">
              <div class="form-floating form-floating-outline mb-4">
                  <input class="form-control" v-model="filter.search" type="text" placeholder="">
                  <label for="html5-date-input">البحث اسم العائلة او رقم الهاتف </label>
              </div>
          </div>
          <div class="head-label col-md-3">
              <div class="form-floating form-floating-outline mb-4">
                <select class="form-control form-select" v-model="filter.village">
                    <option value=""></option>
                    <option v-for="item in loopVillage" :key="item" :value="item.id">{{ item.name }}</option>
                  </select>
                  <label for="html5-date-input">القري والمناطق</label>
              </div>
          </div>
          <div class="head-label col-md-3">
              <div class="form-floating form-floating-outline mb-4">
                  <select class="form-control form-select" v-model="filter.street">
                    <option value=""></option>
                    <option v-for="item in loopStreet" :key="item" :value="item.id">{{ item.name }}</option>
                  </select>
                  <label for="html5-date-input">الشوارع او العائلات</label>
              </div>
          </div>
          <div class="head-label col-md-3">
            <button class="dt-button create-new btn btn-primary" 
                      tabindex="0" 
                      data-toggle="modal" 
                      data-target="#newModal" 
                      aria-controls="DataTables_Table_0" 
                      type="submit">
                      <span>
                        <i class="mdi mdi-plus me-sm-1"></i> 
                        <span class="d-none d-sm-inline-block">اضافة</span>
                      </span>
              </button>
          </div>
        </form>

        <table class="datatables-basic table table-bordered">
              <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>الاســـــم</th>
                  <th>الهاتف</th>
                  <th>واتساب</th>
                  <th style="width: 130px;">تحكم</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(item, index) in loopFamily" :key="item">
                <td> {{ index + 1  }}  </td>
                <td> <router-link :to="'/family.members/'+item.id">{{ item.name }}  </router-link></td>
                <td> <router-link :to="':tel/'+item.mobile">{{ item.mobile }}  </router-link></td>
                <td> <router-link :to="'/family/'+item.whatsapp">{{ item.whatsapp }}  </router-link></td>
                
                <td>
                  <a class="btn-action" @click="onDelete(item.id)"> 
                    <i class="far fa-trash-alt"></i> 
                  </a>
                  <a  class="btn-action" 
                      data-toggle="modal" 
                      data-target="#EditModal" 
                      @click="openEditModel(item.id, item.name, item.mobile, item.whatsapp)"> 
                      <i class="fas fa-pencil-alt"></i> 
                  </a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>



  </div> <!-- container -->   
</template>





<script>
import axios from 'axios'
 export default {
  name: "foods-s",

  data() {
    return {
      // Validation
      loopFamily : [],
      loopVillage : [],
      loopStreet : [],

      name : '',
      mobile : '',
      whatsapp : '',

      edit_id: '',
      edit_name : '',
      edit_mobile : '',
      edit_whatsapp : '',

      filter: {
        search          : "",
        village         : "",
        street          : "",
      }

      
    }
  },

  mounted () {
      window.document.title = "الشوارع او العائلات";
      if (!localStorage.getItem("status_auth")) {
        window.location.href = '/';
      }
      this.getData();
  }, // mounted

  methods : {

    getData(){
      axios.get(`family.search`)
        .then(res => {
          this.loopFamily     = res.data.family
          this.loopVillage    = res.data.village
          this.loopStreet     = res.data.street
        })
    },


    filtter() {
        axios.get("family.search"
        + "?search="     + this.filter.search
        + "&village="    + this.filter.village
        + "&street="     + this.filter.street
        ) 
        .then(res => {
            this.loopFamily = res.data.family;
          })
      },





 }
}
</script>
   